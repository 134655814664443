@import 'projects/obyte/src/styles/variables';
@import 'projects/obyte/src/styles/mixins';

$type-lighten: 12%;
$type-lighten-hover: 2%;
$sub-menu-color: darken($idro-palette-second, 20%);

#menu {
  float: right;
  display: block;
  width: auto;
  height: 55px;
  padding: 0;
  //text-transform: uppercase;
  font-variant: small-caps;
  font-size: 14px;
  li {
    list-style-type: none;
  }
  .menu-item {
    display:block;
    float:left;
    padding:0 1px;
    // margin-top: -8px;
    z-index:10;
    position:relative;
    .menu-sub {
      width: 160px;
      margin: 0 -39px;
      // TODO menu che si vede con un piccolo rimbalzo
      // transition: opacity 100ms ease 0s, transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
      // transform: translate(0px, -10px);
      position: absolute;
      top: 76px;
      z-index: 3;
      text-shadow: 1px 1px 1px #000;
      display: none;
      color: #183c7e;
      i {
        width: 16px;
        display: block;
        margin: 0 auto;
      }
    }
    &:hover .menu-sub .menu-sub-item:hover {
      color: #fefeff;
    }
    &:hover .menu-sub {
      display: block;
    }
    @mixin toggleItem($color) {
      color: lighten($color, $type-lighten);
      &:hover {
        background: lighten($color, $type-lighten-hover);
        &::before {
          border-bottom-color: lighten($color, $type-lighten-hover);
        }
        &::after {
          border-top-color: lighten($color, $type-lighten-hover);
        }
      }
    }
    .menu-sub-item {
      display: inline-block;
      width: 80px;
      padding: 12px 0;
      @include border-radius(100%);
      &:nth-child(3), &:nth-child(4), &:nth-child(8) {
        transform: translateX(40px);
      }
      &:nth-child(7) {
        transform: translate(-40px, -120px);
      }
      a {
        display: block;
        line-height: 24px;
        font-size: $font-size-base;
        text-align: center;
        font-weight: bolder;
        margin: 0 2px;
        position: relative;
        background: $sub-menu-color;
        filter: drop-shadow(2px 2px 2px transparentize(#000000, 0.3));

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          width: 0;
          border-left: 38px solid transparent;
          border-right: 38px solid transparent;
        }
        &::before {
          bottom: 100%;
          border-bottom: 20px solid $sub-menu-color;
        }
        &::after {
          top: 100%;
          width: 0;
          border-top: 20px solid $sub-menu-color;
        }

        &.buildings {@include toggleItem($idro-gallery-buildings);}
        &.characters {@include toggleItem($idro-gallery-characters);}
        &.weapons {@include toggleItem($idro-gallery-weapons);}
        &.animals {@include toggleItem($idro-gallery-animals);}
        &.objects {@include toggleItem($idro-gallery-objects);}
        &.monsters {@include toggleItem($idro-gallery-monsters);}
        &.vehicles {@include toggleItem($idro-gallery-vehicles);}
        &.foods {@include toggleItem($idro-gallery-foods);}
        &.renders {@include toggleItem(lighten($idro-gallery-renders, 8%));}
        &.videos {@include toggleItem(lighten($idro-gallery-videos, 8%));}
        &.others {@include toggleItem(lighten($idro-gallery-others, 8%));}

        &:hover {
          color: white;
        }
      }
    }
    .fas {
      //width: 16px;
      font-size: 12px;
      text-align: center;
      //margin-left: 3px;
    }
  }
  .menu-link {
    font-family: $idro-font-family-menu;
    text-shadow: 0 0 2px transparentize(#000, .4);
    float: left;
    margin: 0;
    z-index: 10;
    position: relative;
    line-height: 80px;
    font-weight: bold;
    width: 82px;
    height: 82px;
    text-align: center;
    //background-position: -430px 0;
    &.double-line {
      line-height: 14px;
      // padding: 27px 0;
    }
    /*> .fas {
      margin-left: 0;
    }*/
    .external {
      font-size: 8px;
      position: absolute;
      color: transparentize($idro-palette-font, .3);
      margin-left: 6px;
    }
    > i {
      @include transition(all .2s);
    }
    &:hover { // Giant icon without text on hover
      //background-position: -430px -83px;
      > i {
        width: auto;
        font-size: 27px;
      }
      > span, .external {
        display: none;
      }
    }
  }
  a.selected span {
    cursor: default;
    color: lighten($idro-palette-highlighted, 20%);
    //background-position: -430px -83px;
  }

  #menu-toggle {
    @include toggle();
    display: none;
    padding: 0 20px;
    margin-right: 0;
    margin-top: 18px;
    .icon-bar {
      display: block;
      background-color: darken($idro-menu, 0.2); // @see toggle() color
      width: 6px;
      height: 6px;
      border-radius: 3px;
      margin-bottom: 5px;
    }
    &:hover i {
      background-color: white;
    }
  }
}
//span.menu-link:hover {
//  animation: vocemRotation 1s 1 ease;
//}
//@keyframes vocemRotation{from{transform:rotateX(20deg)}to{transform:rotateX(0deg)}}
//@-webkit-keyframes vocemRotation{from{-webkit-transform:rotateX(20deg)}to{-webkit-transform:rotateX(0deg)}}
//@-moz-keyframes vocemRotation{from{-moz-transform:rotateX(20deg)}to{-moz-transform:rotateX(0deg)}}
//@-o-keyframes vocemRotation {from{-o-transform:rotateX(20deg)}to{-o-transform:rotateX(0deg)}}

#header.fixed {
  #menu-toggle {
    margin-top: 18px;
  }

  span.menu-link:hover {
    animation: none;
  }
}

// LITTLE PC
@media #{$tablet-menu} {
  // Menu
  #menu {
    #menu-toggle {
      display: block;
    }
    #main-menu {
      display: none;
      float: right;
      padding: 10px 0 20px 2px;
      z-index: 600;
      background-color: darken(transparentize($idro-palette-back, .3), 5%);
      box-shadow: -10px 10px 15px 5px rgba(0,0,0,.4);
      width: 244px;
      min-height: 100%;
      &.navOpen {
        display: block;
      }
      .menu-link {
        width: 200px;
        height: 80px;
        line-height: 80px;
        background: $idro-palette-back;
        margin: 0;
        position: relative;
        &.double-line {
          padding: 5px 0;
          margin: 0 20px;
          height: 60px;
          line-height: 22px;
          font-size: 16px;
        }
        &:hover > i {
          width: auto;
          font-size: 27px;
          line-height: 50px;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: auto;
          width: 0;
          border-top: 30px solid transparent;
          border-bottom: 30px solid transparent;
        }
        &::before {
          right: 100%;
          border-right: 18px solid $idro-palette-back;
        }
        &::after {
          left: 100%;
          width: 0;
          border-left: 18px solid $idro-palette-back;
        }
      }
      .menu-item {
        float: none;
        z-index: 100;
        padding: 2px 0;
        clear: both;
        > a {
          display: block;
          height: 60px;
          overflow: hidden;
        }
        .menu-sub {
          position: inherit;
          display: block !important;
          background: none;
          margin: 0 auto 28px;
          box-shadow: none;
          top: 16px;
          height: 180px;
          width: 160px;
          &.graphics {
            height: 60px;
            width: 240px;
            .menu-sub-item:nth-child(3) {
              transform: translateX(0);
            }
          }
        }
      }
    }
    &.fixed .menu-item:hover {
      margin-top: 0;
      background-color: transparent;
    }
  }

  .mobile-menu-back.navOpen {
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: transparentize(#0f2b38, .3);
    overflow-y: scroll;
    overflow-x: hidden;
    resize: none;
    &::before { // TODO Make it like a glass class.
      content: '';
      position: fixed;
      width: 96%;
      height: 100%;
      left: 0;
      top: 0;
      right: 20px;
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
    }
  }
}

// PC SMALL
@media #{$tablet-s} {
  #menu {
    margin: 0 10px 0 0;
    &.fixed {
      margin: 0 15px 0 0;
    }
  }
}

// MOBILE
@media #{$tablet-xs} {
  #header {
    height: 86px;
  }
  #menu {
    &.fixed {
      display: block;
    }
    #main-menu {
      display: none;
      &.navOpen {
        display: block;
      }
    }
  }
}
@media #{$tablet-xxs} {
  .home #menu:not(.fixed) {
    margin: 2px 0 0 0;
    #menu-toggle {
      background: none;
      &:before, &:after {
        border-bottom-color: transparent;
        border-top-color: transparent;
      }
    }
  }
}

/** MOBILE MEDIUM */
@media #{$mobile-m} {
  #menu.fixed {
    margin: 0 10px 0 0;
  }
}

@media #{$mobile-s} {
  #menu {
    margin: 0 10px 0 0;
  }
}

@media #{$pc-menu} {
  #menu {
    height:66px;
    .menu-link {
      background-image: none;
      height: 46px;
      line-height: 66px;
      padding: 9px 0;
      margin-top: 10px;
      position: relative;
      &.double-line {
        line-height: 14px;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        border-left: 42px solid transparent;
        border-right: 42px solid transparent;
      }
    }
    .menu-item {
      .menu-sub {
        margin: 0 -39px 0;
        top: 59px;
      }

      &:hover {
        margin-top: 9px;
        background-color: darken($idro-palette-second, 2%);
        .menu-link {
          margin-top: 0;
          &::before {
            bottom: 100%;
            border-bottom: 20px solid darken($idro-palette-second, 2%);
          }
          &::after {
            top: 100%;
            width: 0;
            border-top: 20px solid darken($idro-palette-second, 2%);
          }
        }
      }
    }
  }
}
