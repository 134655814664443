@import 'projects/obyte/src/styles/variables';
@import 'projects/obyte/src/styles/mixins';

/** Scrollbar */
*::-webkit-scrollbar {
  width:1em;
}
*::-webkit-scrollbar-track {
  background-color: #000;
  -webkit-box-shadow: inset 0 0 6px rgba(145,141,141,0.9);
}
*::-webkit-scrollbar-thumb {
  // background-color: #2C5553;
  background: -webkit-gradient(linear, left top, right bottom,
    from($idro-palette-back),
    to(darken($idro-palette-back, 5%)));
  border-radius: 4px;
}
/** FadeIn */
.fade-in {
  $duration: .7s;
  -webkit-animation:fadeIn ease-in $duration;
  -moz-animation:fadeIn ease-in $duration;
  -o-animation:fadeIn ease-in $duration;
  animation:fadeIn ease-in $duration;
  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  -o-animation-fill-mode:forwards;
  animation-fill-mode:forwards;
}
@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}
@-moz-keyframes fadeIn{from{opacity:0}to{opacity:1}}
@-o-keyframes fadeIn{from{opacity:0}to{opacity:1}}
@keyframes fadeIn{from{opacity:0}to{opacity:1}}

/** FlyIn */
//.flyIn {
//  animation: flyIn 0.8s forwards;
//  -webkit-animation: flyIn 0.8s forwards;
//  -moz-animation: flyIn 0.8s forwards;
//  -o-animation: flyIn 0.8s forwards;
//}
//@keyframes flyIn {100% { transform: translateX(0%); }}
//@-webkit-keyframes flyIn {100% { -webkit-transform: translateX(0%); }}
//@-moz-keyframes flyIn {100% { -moz-transform: translateX(0%); }}
//@-o-keyframes flyIn {100% { -o-transform: translateX(0%); }}

/** Selection */
p::selection, a::selection, span::selection, br::selection, legend::selection, img::selection, div::selection,
input::selection, b::selection, td::selection, label::selection, button::selection, strong::selection, em::selection,
time::selection, code::selection, h1::selection, h2::selection, h3::selection, h4::selection, h6::selection,
p::-moz-selection, a::-moz-selection, span::-moz-selection, br::-moz-selection, legend::-moz-selection, img::-moz-selection,
label::-moz-selection, div::-moz-selection, input::-moz-selection, b::-moz-selection,
td::-moz-selection, button::-moz-selection, strong::-moz-selection, em::-moz-selection, time::-moz-selection,
code::-moz-selection, h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h6::-moz-selection {
  background: lighten($idro-palette-back, 15%);
}
