//Usage: @include border-radius(10px);
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

//Usage: @include box-shadow(0 4px 11px rgba(0, 0, 0, 0.8));
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow
}
@mixin box-shadow-2($shadow, $shadow2) {
  -webkit-box-shadow: $shadow, $shadow2;
  -moz-box-shadow: $shadow, $shadow2;
  box-shadow: $shadow, $shadow2
}
@mixin box-shadow-custom() {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

//Usage: @include transform(scale(10%));
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  // -ms-transform: $transform;
  transform: $transform;
}

//Usage: @include transition(all 0.2s ease-in-out);
@mixin transition($settings) {
  -webkit-transition: $settings;
  -moz-transition: $settings;
  -o-transition: $settings;
  // -ms-transition: none;
  transition: $settings;
}
@mixin transition2($settings, $settings2) {
  -webkit-transition: $settings, $settings2;
  -moz-transition: $settings, $settings2;
  -o-transition: $settings, $settings2;
  // -ms-transition: none;
  transition: $settings, $settings2;
}

@mixin tooltip-background($color) {
  // .arrow::before {border-bottom-color: $color}
  .arrow { display: none }
  .tooltip-inner {
    font-weight: bold;
    letter-spacing: 1px;
    background-color: $color;
  }
}

@mixin toggle($dark: 0) {
  float: right;
  width: 44.8px;
  height: 28px;
  cursor: pointer;
  padding: 0 10px;
  margin: 27px 6px 0 0;
  color: darken($idro-menu, 0.2);
  background: transparentize(darken($idro-palette-second, $dark), .3);
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
  }
  &:before {
    bottom: 100%;
    border-bottom: 15px solid transparentize(darken($idro-palette-second, $dark), .3);
  }
  &:after {
    top: 100%;
    width: 0;
    border-top: 15px solid transparentize(darken($idro-palette-second, $dark), .3);
  }
  &:hover {
    background: transparentize(darken($idro-palette-second, $dark), .6);

  }
  &:hover, &.open {
    &:before,
    &:after {
      border-top-color: transparentize(darken($idro-palette-second, $dark), .6);
      border-bottom-color: transparentize(darken($idro-palette-second, $dark), .6);
    }
    i {
      color: white;
    }
  }
  &.open {
    background: transparentize(darken($idro-palette-second, $dark), .6);
  }
  @media #{$tablet-s} {
    margin-top: 13px;
    // margin-right: 6px;
  }
}

/** Tags */
@mixin tags() {
  .tags {
    display: inline-block;
    width: auto;
    height: 26px;
    background-color: lighten($idro-palette-back, 2%);
    @include border-radius(2px 4px 4px 2px);
    border-left: 1px solid lighten($idro-palette-back, 2%);
    margin: 2px 5px 2px 13px;
    position: relative;
    color: white;
    line-height: 26px;
    padding: 0 10px 0;
    &:hover {
      background-color: lighten($idro-palette-back, 10%);
      border-left: 1px solid lighten($idro-palette-back, 10%);
      span {
        text-decoration: underline;
      }
      &:before {
        border-right: 13px solid lighten($idro-palette-back, 10%);
      }
    }
    &:before {
      content: "";
      position: absolute;
      display: block;
      left: -13px;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-bottom: 13px solid transparent;
      border-right: 13px solid lighten($idro-palette-back, 2%);
    }
    &:after {
      content: "";
      background-color: darken($idro-palette-back, 5%); // Tag hole.
      border-radius: 50%;
      width: 4px;
      height: 4px;
      display: block;
      position: absolute;
      left: -9px;
      top: 11px;
    }
  }
}

// =============================================================================
// String Replace
// =============================================================================
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace
    + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}
