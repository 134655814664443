@import 'projects/obyte/src/styles/variables';
@import 'projects/obyte/src/styles/mixins';

html {
  height: 100%;
}

* {
  outline: none;
}

body,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  &.app {
    outline: none;
    //background-color: #000;
    background-color: #180a26;
    //background-image: url("https://tools.obyte.it/assets/backs/back.webp");
    background-attachment: fixed;
    font-size: $font-size-base;
    //font-family: $idro-font-family;
    color: $idro-palette-font;
    position: relative;
    min-height: 100%;
    padding: 70px 0 190px;
    &.home {
      padding: 100px 0 190px;
    }
    margin: 0;
    text-indent: 0;
    line-height: 16px;
    min-width: $min-width;
  }
  &.about-me {
    padding: 0 0 0;
  }
}
h1, h2, h3, h4 {
  clear: both;
  margin: 0 0 1em;
  color: $idro-palette-font;
  font-size: $font-size-h;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 4px transparentize($idro-shadow, 0.25);
}
h2 {
  font-size: 14px
}
p {
  text-align: justify;
  margin: 0 25px;
  text-shadow: 1px 1px 0 $idro-shadow2;
}
label, .label {
  color: #e0f2f3;
}
span, ul, li, div, a, br, tr, td, fieldset, label, img, button, form, input, textarea {
  margin: 0;
  padding: 0;
  border: none
}
li {
  list-style-position: inside;
}
button {
  background: black;
}
button:focus {
  outline: 2px solid transparentize($idro-palette-second, .4);
}

hr {
  border-top: 1px solid $idro-palette-second;
  margin: 1rem auto 1.5rem;
  width: 60%;
  &:before, &:after {
    content: '';
    height: 21px;
    display: block;
  }
  &:before {
    margin-top: -11px;
    border-left: 1px solid $idro-palette-second;
  }
  &:after {
    margin-top: -21px;
    border-right: 1px solid $idro-palette-second;
  }
}

.sprite_world {
  background-image: url("https://tools.obyte.it/img/idrobyte/sprites.png?v=3.0");
  // background-image: url("https://tools.obyte.it/img/idrobyte/sprites.png?v=1.6");
  background-repeat: no-repeat;
}
.def_back {
  // background: url("https://tools.obyte.it/assets/backs/background_v3.png") repeat;
  background-color: transparentize($idro-palette-back, .3); // #23696542 // #8400c35e
}
.auth_back {
  background-color: transparentize(#2f2f2f, 0.15);
  &.dropdown-menu {
    background-color: #2f2f2f;
  }
}

/** All */
body {
  a {
    cursor: pointer;
    &, &:link, &:visited {
      color: $idro-palette-font;
      text-decoration: none
    }
    &:hover, &:active {
      color: #fff;
      text-decoration: none
    }
  }
}

/***************** LOADING *******************/
.background-loading {
  width: 100%;
  height: 100%;
  //background-image: radial-gradient(closest-corner at 50% 250px, rgba(37, 75, 56, 0.89), #000000);
  position: absolute;
  top: 0; left: 0; right: 0;
  z-index: -1;
}

/*** LOADING ************************************************/
/** https://codepen.io/Sandman90/pen/yLXLyoq */
$l-base-color: rgba(88, 210, 191, 0.5);
$l-translation: 28px;
$l-size: 50px;
$l-duration: 8s;

.c-loading {
  width: $l-size;
  height: $l-size;
  position: absolute;
  z-index: -1;
  top: 40%;
  left: 50%;
  margin: 30px 0 0 -25px;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  .cube {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transform-style: preserve-3d;
    -webkit-animation: rotatecube $l-duration infinite;
    transform-style: preserve-3d;
    animation: rotatecube $l-duration infinite;
    div {
      width: $l-size;
      height: $l-size;
      line-height: $l-size;
      display: block;
      position: absolute;
      border: none;
      text-align: center;
      font-size: 50px;
      font-weight: bold;
      border-radius: 6px;
    }
    .front {
      background: darken($l-base-color, 5%);
    }
    .right, .left {
      background: lighten($l-base-color, 10%);
    }
    .top, .bottom {
      background: darken($l-base-color, 20%);
    }
    .back {
      background: darken($l-base-color, 30%);
    }
  }

  &.mini {
    width: $l-size - 10;
    height: $l-size - 10;
    margin: -20px 0 0 -20px;
    .cube div {
      width: $l-size - 10;
      height: $l-size - 10;
      line-height: $l-size - 10;
    }
  }
  &.big {
    width: $l-size + 30;
    height: $l-size + 30;
    margin: 40px 0 0 -40px;
    .cube div {
      width: $l-size + 30;
      height: $l-size + 30;
      line-height: $l-size + 30;
    }
  }
}

@keyframes rotatecube {
  0% { transform: rotateX(315deg) rotateY(180deg) rotateZ(225deg); }
  50% { transform: rotateX(45deg) rotateY(0deg) rotateZ(135deg); }
  100% { transform: rotateX(315deg) rotateY(180deg) rotateZ(225deg); }
}

@-webkit-keyframes rotatecube {
  0% { -webkit-transform: rotateX(315deg) rotateY(180deg) rotateZ(225deg); }
  50% { -webkit-transform: rotateX(45deg) rotateY(0deg) rotateZ(135deg); }
  100% { -webkit-transform: rotateX(315deg) rotateY(180deg) rotateZ(225deg); }
}
@-moz-keyframes rotatecube {
  0% { -moz-transform: rotateX(315deg) rotateY(180deg) rotateZ(225deg); }
  50% { -moz-transform: rotateX(45deg) rotateY(0deg) rotateZ(135deg); }
  100% { -moz-transform: rotateX(315deg) rotateY(180deg) rotateZ(225deg); }
}

.c-loading {
  @mixin face($class, $direction) {
    .cube .#{$class} {
      -webkit-transform: rotate#{$direction} translateZ( $l-translation );
      -moz-transform: rotate#{$direction} translateZ( $l-translation );
    }
    &.mini .cube .#{$class} {
      -webkit-transform: rotate#{$direction} translateZ( $l-translation - 10 );
      -moz-transform: rotate#{$direction} translateZ( $l-translation - 10 );
    }
    &.big .cube .#{$class} {
      -webkit-transform: rotate#{$direction} translateZ( $l-translation + 14 );
      -moz-transform: rotate#{$direction} translateZ( $l-translation + 14 );
    }
  }

  @include face('front', 'Y(0)');
  @include face('back', 'X(180deg)');
  @include face('right', 'Y(90deg)');
  @include face('left', 'Y(-90deg)');
  @include face('top', 'X(90deg)');
  @include face('bottom', 'X(-90deg)');
}

.loading-background {
  //min-height: 512px;
  // min-width: 100%;
  background-color: transparentize(black, 0.5);
  box-shadow: 0 0 70px 10px rgb(11, 77, 67) inset;
  border-radius: 10%;
  @media #{$tablet} {
    min-height: 220px;
  }
  @media #{$tablet-xs} {
    min-height: 150px;
  }
}
.loading-background {
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  float: left;
  height: 0;
}

// Models item prev-next loading
.lds-css.mini {
  margin-top: 0;
  position: absolute;
  z-index: 1;
  height: 100%;
  display: block;
  width: 100%;
  background: rgba(0, 0, 0, .7);
  border-radius: 100%;
}

/*
@keyframes lds-eclipse {
  0% {transform: rotate(0deg);}
  50% {transform: rotate(180deg);}
  100% {transform: rotate(360deg);}
}
@-webkit-keyframes lds-eclipse {
  0% {-webkit-transform: rotate(0deg);}
  50% {-webkit-transform: rotate(180deg);}
  100% {-webkit-transform: rotate(360deg);}
}
.lds-eclipse {
  position: relative;
  margin: 0 auto;
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  div {
    position: absolute;
    -webkit-animation: lds-eclipse 1s linear infinite;
    animation: lds-eclipse 1s linear infinite;
    width: 160px;
    height: 160px;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    box-shadow: 0 4px 0 0 #15b8a5;
    -webkit-transform-origin: 80px 82px;
    transform-origin: 80px 82px;
  }
  &.mini {
    width: 100px !important;
    height: 100px !important;
    -webkit-transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
    transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
    div {
      width: 100px;
      height: 100px;
      top: 15px;
      left: 15px;
      -webkit-transform-origin: 50px 50px;
      transform-origin: 50px 50px;
    }
  }
  @media #{$mobile} {
    width: 80px !important;
    height: 80px !important;
    transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
    div {
      width: 80px;
      height: 80px;
      top: 0;
      left: 0;
      -webkit-transform-origin: 40px 41px;
      transform-origin: 40px 41px;
    }
  }
}*/

.gallery-error, .gallery-error p, .gallery-error .error-code {
  text-align: center;
  margin: 40px auto 20px;
  color: #cf3333;
  font-size: 30px;
  font-variant: small-caps;
  text-shadow: none;
  i {
    display: block;
    margin-bottom: 20px;
    font-size: 90px;
  }
}
.gallery-error .error-code {
  font-size: 40px;
  margin: 40px 0;
}

.gallery-empty {
  text-align: center;
  margin: 40px auto 20px;
  font-size: 1.5em;
  font-variant: small-caps;
}

/** Fonts ******************************************************************/
// Usage example @include font-face("Helvetica Neue LT Std Lt", "fonts/HelveticaNeueLTStd-UltLt", null, null, otf);
/*
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  src: local('Orbitron Regular'), local('Orbitron-Regular'), url(https://fonts.gstatic.com/s/orbitron/v9/HmnHiRzvcnQr8CjBje6GQvesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
  font-display: swap; // TODO Aggiungere a tutti i font-face e vedere se funziona come consigliato da Google
}*/

/******************************
************ SKILLS ***********
******************************/
dfn {
  &.drupal, &.soap { color: lighten($idro-skill-drupal, 10%); }
  &.node-js { color: lighten($idro-skill-node, 10%); }
  &.svelte { color: lighten($idro-skill-svelte, 10%); }
  &.vue { color: lighten($idro-skill-vue, 10%); }
  &.react { color: lighten($idro-skill-react, 10%); }
  &.angular, &.angularjs { color: lighten($idro-skill-angular, 20%); }
  &.php, &.vbscript, &.phpstorm, &.netbeans, &.intellij { color: lighten($idro-skill-php, 15%); }
  &.wordpress, &.jquery, &.microsoftoffice { color: lighten($idro-skill-wordpress, 30%); }
  &.js, &.javascript, &.ajax, &.qaload { color: $idro-skill-js; }
  &.java, &.webcentersites, &.oracledbms, &.hibernate { color: lighten($idro-skill-java, 20%); }
  &.html, &.mysql, &.sql { color: lighten($idro-skill-html, 20%); }
  &.css, &.scss, &.sunos, &.webstorm { color: $idro-skill-css; }
  &.spring, &.springboot, &.nodejs { color: $idro-skill-spring; }
  &.iis, &.weblogic,&.git, &.svn, &.eclipse, &.bootstrap { color: lighten($idro-skill-systems, 20%); }
  &.jenkins, &.jboss, &.unixshellscripting, &.linux { color: #c0c0c0; }
  &.apachehttpd, &.wsdl, &.silkperformer { color: $idro-skill-apache; }
  &.unity { color: $idro-skill-unity; }
}
