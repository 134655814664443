@import 'projects/obyte/src/styles/variables';
@import 'projects/obyte/src/styles/mixins';

//.input-icon {
//  color: $idro-palette-second;
//}

#container.users-form {
  width: 60%;
  min-height: 220px;
  &.login {
    max-width: 600px;

  }
  .input-link.float-start {
    font-size: 1em;
    padding: 3px 6px;
    margin-right: 8px;
    @media #{$tablet-s} {
      font-size: 0.9em;
      padding: 3px 5px;
      margin-right: 4px;
    }
  }
  @media #{$tablet} {
    width: 80%;
    padding: 35px 0 25px;
  }
  @media #{$tablet-m} {
    width: 100%;
  }

  .input-field {
    background-color: black;
    color: white;
    padding: 0 5px;
  }

  .input-submit, .input-link {
    padding: 10px;
    font-size: 1.4em;
    min-width: 130px;
    // border: 1px solid black;
    @media #{$tablet-s} {
      padding: 20px 4px;
      min-width: 94px;
    }
    @media #{$tablet} {
      width: 80%;
      display: block;
      text-align: center;
    }
  }
  span {
    white-space: pre-wrap;
  }
}

.fa-15x {
  font-size: 1.5em;
}

.fa-25x {
  font-size: 2.5em;
}

.mat-form-field-suffix {
  button {
    background: transparent;
    // border: 0 none;
    color: #e3f4ff;
  }
}
