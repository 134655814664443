// Fonts
$idro-font-family: "Verdana", Arial, sans-serif;
//$idro-font-family-menu: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
$idro-font-family-menu: "Helvetica Neue", Helvetica,Arial, sans-serif;
$idro-font-family-title: "Comfortaa", "Verdana", Arial, sans-serif;
$font-size-base: 0.8125rem; // 13px;
$font-size-s:    0.75rem; // 12px;
$font-size-h:    floor(($font-size-base * 2));
$font-size-h2:   20px; // 18px;

// Palette
$idro-palette-back: #4d076e; // #8400c3;
$idro-palette-first: #5f19cc; // #14907d;
$idro-palette-second: #5f19cc;
$idro-palette-font: #fff; // #daffe9; // #ea9375; // #e3f4ff;
$idro-palette-highlighted: #af96ec;
$idro-palette-dark: #031619;
// $color-transparent-grey: rgba(0, 0, 0, 0.8);
// Util color
$idro-shadow: #1c1c1c;
$idro-shadow2: #606060;
// Menu color
$idro-menu: #b6d7e7;

// Model types
$idro-gallery-buildings: #5868f9;
$idro-gallery-characters: #b077bf; // #64aecf;
$idro-gallery-weapons: #d87876;
$idro-gallery-animals: #53a769;
$idro-gallery-objects: #fbdc6a;
$idro-gallery-monsters: #b077bf;
$idro-gallery-vehicles: #ffa650;
$idro-gallery-foods: #ade843;
// Graphic types
$idro-gallery-renders: #0f83bb;
$idro-gallery-videos: #7b4cc2;
$idro-gallery-others: #2fa74a;
// Tool types
$idro-gallery-js: #096aad;
// Skill types
$idro-skill-drupal: #2baae2;
$idro-skill-angular: #b52e31;
$idro-skill-php: #8892be;
$idro-skill-wordpress: #0283af;
$idro-skill-js: #e4c565;
$idro-skill-java: #fc231d;
$idro-skill-styles: #50c690;
$idro-skill-html: #f16529;
$idro-skill-css: #50c690;
$idro-skill-spring: #6db33f;
$idro-skill-systems: #a15ac0;
$idro-skill-apache: #d7ae1a;
$idro-skill-android: #3adc87;
$idro-skill-html5: #f1a634;
$idro-skill-react: #4fc6e3;
$idro-skill-adobe: #ed1c24;
$idro-skill-svelte: #ed5321;
$idro-skill-vue: #25c754;
$idro-skill-typescript: #2f74c0;
$idro-skill-unity: #5b6b7b;
$idro-skill-node: #439b2b;

// Urls
$host-util: 'https://tools.obyte.it/';

/*
	Extra small
<576px	Small
≥576px	Medium
≥768px	Large
≥992px	X-Large
≥1200px	XX-Large
≥1400px
 */
// Media query variables
$mobile-s: "screen and (max-device-width: 319px)"; // MOBILE-S
$mobile-m: "screen and (max-device-width: 374px)"; // MOBILE-M
$mobile: "screen and (max-device-width: 425px)"; // MOBILE
$tablet-xxs: "(max-width: 374px)"; // TABLET EXTRA EXTRA SMALL
$tablet-xs: "(max-width: 575px)"; // TABLET EXTRA SMALL 425px
$tablet-s: "(max-width: 669px)"; // TABLET SMALL
$tablet-m: "(max-width: 767px)"; // TABLET
$tablet: "(max-width: 991px)"; // LITTLE PC
$tablet-menu: "(max-width: 1200px)"; // LITTLE PC
$pc-menu: "(min-width: 1199px)"; // BIG PC
$min-tablet: "(min-width: 992px)"; // MEDIUM PC
$medium-pc: "(max-width: 1399px)";
$min-width: 300px;
$container-border: 25px;
$container-border-double: 49.5px;


////////////////////////////////////////////////////
// Bootstrap variables
$primary:       $idro-palette-first;
$secondary:     $idro-palette-second;
