@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'projects/obyte/src/styles/variables';
@import 'projects/obyte/src/styles/mixins';

/** Body */
#container {
  position: relative;
  //width: 75%;
  margin: 10px auto 40px;
  min-height: 400px;
  // max-width: 1400px;
  padding: 35px 10px 25px;
  // @include border-radius(25px);
  // overflow: hidden; // overflow: hidden;

  #page-description, .page-description {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    border-top: $container-border solid transparent;
    // border-bottom: 41px solid transparent;
  }
  &:before {
    right: 100%;
    border-right: $container-border solid transparentize($idro-palette-back, .3);
  }
  &:after {
    left: 100%;
    width: 0;
    border-left: $container-border solid transparentize($idro-palette-back, .3);
  }

  #container-border {
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: $container-border;
      width: $container-border;
      height: calc(100% - 49.5px); // $container-border-double
      background-color: transparentize($idro-palette-back, .3);
      // border-top: 41px solid transparent;
      // border-bottom: 41px solid transparent;
    }
    &:before {
      right: 100%;
      // border-right: 24px solid rgba(35, 105, 101, 0.55);
    }
    &:after {
      left: 100%;
      // border-left: 24px solid rgba(35, 105, 101, 0.55);
    }
  }
  #container-bottom {
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 0;
      border-bottom: $container-border solid transparent;
    }
    &:before {
      right: 100%;
      border-right: $container-border solid transparentize($idro-palette-back, .3);
    }
    &:after {
      left: 100%;
      width: 0;
      border-left: $container-border solid transparentize($idro-palette-back, .3);
    }
  }

  h1, h2, h3, h4 {
    font-family: $idro-font-family;
    //text-transform: uppercase;
    font-variant: small-caps;
    &.orb_titles {
      font-variant: small-caps;
      text-transform: capitalize;
      font-family: $idro-font-family-title;
      letter-spacing: 2px;
      font-size: 2.5em;
    }
  }
  .error {
    margin: 0 auto 15px;
    text-align: center;
    width: 50%;
    color: #ffefef;
    background-color: transparentize(#f1afaf, .68);
    border: #f03 solid 1px;
    text-shadow: 1px 1px 1px #000;
    padding: 5px;
    font-weight: 600;
    li {
      display: block;
    }
  }
  .error-box {
    width: 100%;
  }
  .success {
    margin: 0 auto 15px;
    text-align: center;
    width: 50%;
    color: green;
    border: #090 solid 1px;
    background-color: #7cd97c;
    text-shadow: 1px 1px 2px #a4bda0;
    padding: 5px;
    &.final {
      color: white;
      background-color: transparentize(#7cd97c, 0.5);
      padding: 60px 10px;
      font-size: 18px;
      line-height: 26px;
    }
  }
  .thumbs {
    position: relative;
    list-style-type: none;
    text-align: center;
    max-width: 1100px; // 1300px;
    margin: 0 auto;
    .thumb {
      display: inline-block;
      background-color: black;
      margin: 5px;
      width: 182px;
      height: 182px;
      overflow:hidden;
      position: relative;
      border: 2px solid $idro-palette-first;
      // @include border-radius(50px);
      @include box-shadow-2(0 0 10px 1px $idro-shadow2 inset, 0 0 10px 0 #000);
      @include transition(all 0.2s ease-in-out);
      @include border-radius(10px);
      .thumb_title {
        color:#E4BBBB;
        display:block;
        position:absolute;
        width:178px;
        height:20px;
        line-height:22px;
        text-align:center;
        font-size: $font-size-base;
        bottom:-20px;
        left:0;
        text-shadow:1px 1px 1px rgba(0,0,0,0.4);
        background: transparentize($idro-palette-first, 0.7);
        @include transition(all 0.2s ease-in-out);
        @include border-radius(5px);
      }
      &:hover {
        @include box-shadow-2(0 0 40px 1px $idro-shadow2 inset, 0 0 20px 2px #000);
        @include border-radius(20px);
        .thumb_title {
          bottom: 0;
        }
      }
    }
  }
  #return {
    border-bottom: 1px #c6dbd7;
    margin: 0 auto;
    &:hover {
      border-bottom: 1px dotted #c6dbd7;
    }
  }
  .titles {
    line-height: 30px;
    font-size: 28px;
    padding: 0 5px;
    font-family: $idro-font-family-title;
    font-weight: normal;
    letter-spacing: 2px;
    margin: 0 auto 20px;
    text-align: center;
    font-variant: small-caps;

    .fa-13x {
      font-size: 1.3em;
    }

    .title-icon {
      display: block;
      //position: relative;
      //width: 64px;
      //height: 46px;
      //line-height: 46px;
      //font-size: 28px;
      //background-color: opacify($idro-palette-first, 0.5);
      //margin: 2px auto;
      //color: white;
      //text-align: center;
      //overflow: visible;
      //filter: drop-shadow(2px 2px 2px transparentize(#000000, 0.7));
      //&:before,
      //&:after {
      //  content: "";
      //  position: absolute;
      //  top: 0;
      //  width: 0;
      //  border-top: 23px solid transparent;
      //  border-bottom: 23px solid transparent;
      //  z-index: -1;
      //}
      //&:before {
      //  right: 100%;
      //  border-right: 20px solid opacify($idro-palette-first, 0.5);
      //}
      //&:after {
      //  left: 100%;
      //  border-left: 20px solid opacify($idro-palette-first, 0.5);
      //}
    }
    //.hexs {
      //position: relative;
      //width: 210px;
      //height: 34px;
      //line-height: 34px;
      //font-size: 22px;
      //background-color: opacify($idro-palette-first, 0.5);
      //margin: 2px auto;
      //color: white;
      //text-align: center;
      //overflow: visible;
      //filter: drop-shadow(2px 2px 2px transparentize(#000000, 0.7));
      //&:before,
      //&:after {
      //  content: "";
      //  position: absolute;
      //  top: 0;
      //  width: 0;
      //  border-top: 34px solid transparent;
      //  border-bottom: 34px solid transparent;
      //  z-index: -1;
      //}
      //&:before {
      //  right: 100%;
      //  border-right: 20px solid opacify($idro-palette-first, 0.5);
      //}
      //&:after {
      //  left: 100%;
      //  border-left: 20px solid opacify($idro-palette-first, 0.5);
      //}
    //}
  }

  h2.titles, h3.titles {
    font-size: 18px;
    font-weight: bold;
  }

  .loading {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 100 100' xmlns:xlink='http://www.w3.org/1999/xlink'><linearGradient id='gradient' gradientUnits='userSpaceOnUse' x1='30' y1='100' x2='50' y2='0'><stop offset='0' style='stop-color:green' id='firstStop' /><stop offset='1' style='stop-opacity:0.2; stop-color:black' id='lastStop' /></linearGradient><path fill='url(%23gradient)' d='M50.069,0.078c-27.595,0-49.966,22.371-49.966,49.966c0,27.595,22.371,49.966,49.966,49.966   s49.966-22.371,49.966-49.966C100.035,22.448,77.664,0.078,50.069,0.078z M50.01,85.062c-19.316,0-34.976-15.66-34.976-34.976   c0-19.317,15.659-34.976,34.976-34.976c19.317,0,34.977,15.659,34.977,34.976C84.987,69.401,69.327,85.062,50.01,85.062z'><animateTransform attributeName='transform' attributeType='XML' type='rotate' from='0 50 50' to='360 50 50' begin='0s' dur='3s' repeatCount='indefinite'/></path></svg>") !important;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    // background-color: $idro-shadow;
  }

  &.modal {
    width: 100%;
    margin: 0;
    padding: 0;
    @include border-radius(0);
    position: fixed;
    z-index: 9999;
  }
}
.row-item.row-border-top {
  border-top: 1px solid darken($idro-palette-second, 10%);
}
#container-item {
  p {
    font-size: 0.93rem;
    line-height: 23px;
    text-align: center;
    margin: 0;
  }
  #type-item {
    text-shadow: 1px 1px 1px #000;
    float: left;
    margin-left: 10px;
    line-height: 36px;
  }
  text-align: center;
  .row-item {
    margin: 0;
    text-align: center;
    min-height: 20px;
    line-height: 20px;
    padding: 14px 0;
  }
  img.row-item { padding: 0; }
  .get-link a {
    text-decoration: underline;
  }
  #title-item {
    background-color: darken($idro-palette-back, 1%);
    text-shadow: 1px 1px 2px transparentize(#000, 0.2);
    clear: none;
    font-family: $idro-font-family-title;
    font-size: $font-size-h2;
    line-height: 24px;
    text-align: center;
    letter-spacing: 2px;
    min-height: 36px;
    padding: 6px 40px 6px 40px;
  }
  #body-item {
    margin: 14px 0;
    #wrapper-item {
      background-color: black;
      @include border-radius(0.3rem);
      @include box-shadow-custom();
      position: relative;
      #expand, #compress, #expand-mobile, #compress-mobile {
        position: absolute;
        right: 18px;
        bottom: 15px;
        cursor: pointer;
        font-size: 16px;
      }
    }
    img {
      max-width:100%;
      margin: 14px auto;
      display: block;
      @include border-radius(0.3rem);
    }
    .input-link {
      width: 200px;
      text-align: center;
      margin: 10px 0;
    }
    @media #{$tablet-m} {
      &, #imgItem {
        margin: 0 auto;
        border-radius: 0;
      }
    }
  }
  .download-container {
    padding: 20px 0;
    .input-link {
      margin: 0 5px;
    }
  }
  .download-container .input-link, #donate-button .input-link {
    font-size: 16px;
    padding: 5px 16px;
  }
  #donate-button .input-link {
    background-color: #d49f00;
    color: #000; // #003087;
  }
}

.error-label {
  text-align: left;
  color: #cf3333;
  text-shadow: 1px 1px 2px #000;
}

#footer.modal {
  display: none;
}

/****** Cookie compliance *****/
.cc-compliance .cc-btn.cc-dismiss {
  &:hover {
    background-color: #208ca4;
  }
}
.cc-floating.cc-theme-classic {
  margin: 0 10px 0;
}
@media #{$mobile} {
  .cc-window .cc-message {
    font-size: 14px;
    line-height: 18px;
  }
  .cc-window {
    margin: 10px;
  }
}

/** Others */
.errorsBorderImg {
  margin: 50px 0;
  width: 100%;
  height: 40px;
  background: url("https://tools.obyte.it/img/idrobyte/warning_notFound.png") repeat
}

.blur {
  filter: blur(2px);
}

/*********** Carousel ***********/
.graphic_sezs .thumbs {
  overflow: hidden;
}
.thumbs .ngucarousel {
  overflow: visible !important;
}
.nguCarouselPoint {
  list-style-type: none;
  text-align: center;
  padding: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: auto;
  li {
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.55);
    padding: 4px;
    margin: 0 4px;
    transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition: 0.4s;
    &.active {
      background: #6b6b6b;
      transform: scale(1.8);
    }
  }

  @media #{$tablet-s} {
    overflow: auto;
    width: 50%;
    white-space: normal;
    margin: 0 auto;
  }
}

.tooltip {
  &.svelte { @include tooltip-background($idro-skill-svelte) }
  &.vue { @include tooltip-background($idro-skill-vue) }
  &.react { @include tooltip-background($idro-skill-react) }
  &.drupal { @include tooltip-background($idro-skill-drupal) }
  &.node-js { @include tooltip-background($idro-skill-node) }
  &.angular { @include tooltip-background($idro-skill-angular) }
  &.php { @include tooltip-background($idro-skill-php) }
  &.wordpress { @include tooltip-background($idro-skill-wordpress) }
  &.unity { @include tooltip-background($idro-skill-unity) }
}


/*********************************************** PAGINATION ***********************************************************/

.pagination {
  display: block;
  margin: 30px auto 15px;
  padding: 0 10% 0;
  text-align: center;
}

.pagination ul.ngx-pagination {
  li {
    display: inline-block;
    color: $idro-palette-font;
    width: 30px;
    height: 20px;
    margin: 0 5px 10px;
    @include border-radius(20px);
    background: #000;
    // transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    // @include transition(0.4s);
    padding: 0;
    &.current {
      background: $idro-palette-second;
      box-shadow: 0 0 0 0.2rem transparentize($idro-palette-second, .6);
    }
  }
  &::before, &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
  a, button {
    display: inline-block;
    background: transparent;
    cursor: pointer;
    outline: 0;
    color: $idro-palette-font;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 20px;
    @include border-radius(20px);
    text-align: center;
    text-decoration: none;
    &:hover {
      background: $idro-palette-second;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem transparentize($idro-palette-second, .5);
    }
  }
  span {
    color: $idro-palette-font;
    padding-top: 0;
    line-height: 20px;
    @include border-radius(20px);
    font-weight: bold;
    &:hover {
      background: none;
    }
  }
  .disabled {
    padding: 0;
    background: #878787;
    cursor: default;
    &:hover {
      background: #979797;
    }
  }
  .show-for-sr,
  .pagination-previous,
  .pagination-next,
  .pagination-previous.disabled,
  .pagination-next.disabled,
  .pagination-previous.disabled::before,
  .pagination-next.disabled::after,
  .small-screen {
    display: none;
  }
  @media #{$tablet-m} {
    li, span, a, button {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
    span {
      display: block;
      padding-top: 10px;
      font-size: 18px;
    }
  }
}


/******************************
*********** MOBILE ***********
******************************/
/**LITTLE PC*/
@media #{$tablet-menu} {
  body.mobile-hidden {
    overflow: hidden!important;
  }
  #container {
    width: 89%;
  }
}
@media #{$tablet-m} {
  #container {
    width: 100%;
    margin: 6px auto 12px;
    min-height: 400px;
    padding: 35px 0 25px;
    @include border-radius(0);
    &:before, &:after, #container-border, #container-bottom {
      display: none;
    }
    overflow: hidden;

    .thumbs {
      padding: 0 10px;
    }
  }
}
@media #{$tablet-s} {
  .no-mobile-s {
    display: none !important;
  }
}

/** MOBILE SMALL */
@media #{$tablet-xs} {
  #container-item {
    #title-item {
      min-height: 38px;
    }
  }
  .no-mobile-s {
    display: none !important;
  }
}

/*@media only screen and (min-width:809px) and (min-device-width:809px){}*/


/******************************
********* FULL SCREEN *********
******************************/

body .modal.fullscreen { // .fullscreen
  #modal__inner {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  @media screen and (max-width: 767px) {
    .modal__close {
      background: transparent !important;
      &::before, &::after {
        background: white !important;
      }
    }
    #type-item, #title-item {
      display: none;
    }
  }
  @media only screen
  and (min-width: 320px)
  and (max-width: 480px) {
    transform: rotate(90deg) translateY(-100%);
    transform-origin: top left;
    height: 100vw;
    width: 100vh;
    #modal__inner {
      background: darken($idro-palette-back, 5%);
    }
  }
}

//@media screen and (min-width: 320px) and (max-width: 767px) { // and (orientation: landscape) {
//  .modal.fullscreen {
//    #modal__inner {
//      transform: rotate(-90deg);
//      transform-origin: left top;
//      width: 100vh;
//      height: 100vh;
//      overflow-x: hidden;
//      position: absolute;
//      top: 100%;
//      left: 0;
//    }
//  }
//}
