@use '@angular/material' as mat;
@include mat.core();

@import 'projects/obyte/src/styles/variables';
@import 'projects/obyte/src/styles/mixins';

//@import '@angular/material/theming';
@import 'node_modules/@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$idro-app-primary: mat.define-palette(mat.$deep-purple-palette, A700, A200, A400);
$idro-app-accent:  mat.define-palette(mat.$deep-purple-palette, A700, A200, A700);

// The warn palette is optional (defaults to red).
$idro-app-warn:    mat.define-palette(mat.$red-palette, A100, A100, A100);

// Create the theme object (a Sass map containing all of the palettes).
//$idro-app-theme: mat.define-dark-theme($idro-app-primary, $idro-app-accent, $idro-app-warn);
// Define a dark theme
$idro-app-theme: mat.define-dark-theme((
  color: (
    primary: $idro-app-primary,
    accent: $idro-app-accent,
  ),
  // Only include `typography` and `density` in the default dark theme.
  typography: mat.define-typography-config(),
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($idro-app-theme);

.mat-expansion-panel {
  background: transparentize($idro-palette-second, .55);
}

.mat-expansion-panel-body {
  padding-top: 16px !important;
}

@keyframes shake {
 0% { transform: translate(1px, 1px) rotate(0deg); }
 30% { transform: translate(-1px, -1px) rotate(-2deg); }
 60% { transform: translate(-2px, 0px) rotate(2deg); }
 //60% { transform: translate(-1px, -1px) rotate(-2deg); }
 //80% { transform: translate(1px, 1px) rotate(0deg); }
 100% { transform: translate(1px, -1px) rotate(1deg); }
}

.input-submit, .input-link {
  cursor: pointer;
  padding: 5px 10px;
  background-color: darken($idro-palette-second, 10%);
  border-radius: 40px;
  color: white;
  font-family: $idro-font-family-title;
  &.secondary {
    background-color: transparent;
    border: 2px solid darken($idro-palette-second, 10%);
  }
  // @include box-shadow-custom();
  &:hover {
    background-color: lighten($idro-palette-second, 5%);
  }
  &:active {
    background-color: $idro-palette-second;
  }
  &:disabled {
    cursor: default;
    background-color: lighten(black, 30%);
    color: darken(white, 30%);
  }
  &:disabled:hover:not(.loading-input) {
    $duration: .2s;
    -webkit-animation: shake ease-in $duration;
    -moz-animation: shake ease-in $duration;
    -o-animation: shake ease-in $duration;
    animation: shake ease-in $duration;
  }
}
.input-row {
  margin: 15px auto;
}
.submit-row {
  text-align: right;
}
